.form-input {

  @apply border-0 border-b border-secondary pb-2 px-4 bg-transparent focus:outline-0 focus:border-primary w-full;

  &.icon {
    @apply pl-8;
  }

  &.error {
    @apply bg-red-300/20 text-red-700;
  }


}
